import { ApolloError } from '@apollo/client';
import { Images, Routes } from 'client/enums';
import Link from 'next/link';
import { useEffect } from 'react';
import { logError } from 'client/logging';
import { PageWrapper } from './PageWrapper';

interface ErrorPageProps {
  status: '404' | '500';
  description?: string;
  title?: string;
  error?: ApolloError;
}

interface StatusType {
  image: Images;
  imageClassName?: string;
  Button: () => JSX.Element;
  subText: string[];
  headerText: string;
  topText?: string;
}

const status404: StatusType = {
  Button: () => (
    <Link href={Routes.INDEX}>
      <button className="button--primary button--fullWidth">Go Home</button>
    </Link>
  ),
  headerText: 'Yikes!',
  image: Images.Error404,
  subText: [
    "We're burnt out. So this link.",
    'Sorry about that.',
    'Go home and try again?',
  ],
};

const status500: StatusType = {
  Button: () => (
    <button
      className="button--primary button--fullWidth"
      onClick={() => window.location.reload()}
    >
      Try Again
    </button>
  ),
  headerText: 'Like the Indy 500 but worse.',
  image: Images.Error500,
  imageClassName: 'w-[400px] md:w-[800px] right-0 top-[200px] md:top-[300px]',
  subText: ['Looks like this server spun out.'],
  topText: 'The OH NO',
};

const statusToFields: {
  [key: string]: StatusType;
} = {
  404: status404,
  500: status500,
};

export const ErrorPage = ({
  status,
  description,
  title,
  error,
}: ErrorPageProps) => {
  const {
    topText,
    imageClassName,
    image,
    headerText,
    subText,
    Button,
  } = statusToFields[status];

  useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  return (
    <PageWrapper description={description} title={title}>
      <div className="relative w-full flex flex-col items-center justify-center pt-22">
        {topText && <p className="text-3xl md:text-5xl">{topText}</p>}
        <p className="text-[200px] md:text-[350px] font-extrabold text-gray-200">
          {status}
        </p>
        <img
          alt={status}
          className={`absolute w-[250px] md:w-[400px] top-[125px] md:top-[200px] ${imageClassName}`}
          src={image}
        />
        <div className="flex flex-col space-y-4 items-center w-full">
          <p className="text-3xl md:text-5xl">{headerText}</p>
          {subText.map((text) => (
            <p className="font-extrabold uppercase" key={text}>
              {text}
            </p>
          ))}
          <Button />
        </div>
      </div>
    </PageWrapper>
  );
};
